<script lang="ts" setup>
import { filename } from 'pathe/utils';

const RegisterFirstKey = defineAsyncComponent(() => import('~coreComponents/auth/Fido/RegisterFirstKey.vue'));

const { $i18n } = useNuxtApp();

useHead({
  titleTemplate: `%s - ${$i18n.t('seo.titlePLK')}`
});

const route = useRoute();

const glob = import.meta.glob('~coreAssets/images/*.jpg', {
  eager: true
});

// @ts-expect-error
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value.default]));

const image = computed(() => {
  return route.meta.imageName ?? 'login';
});

const getContentWidth = computed(() => `${route.meta.contentWidth ?? 440}px`);
</script>

<template>
  <div class="auth">
    <div class="auth__content">
      <NuxtLink
        :to="{ name: $routeNames.index }"
        class="auth__logo"
      >
        <img
          src="@/assets/images/plk-logo.svg"
          alt="Portal Licytacji Komorniczych"
        />
      </NuxtLink>

      <slot />
    </div>
    <div
      v-if="typeof image === 'string' && images[image]"
      class="auth__image-container"
    >
      <img
        :src="images[image]"
        class="auth__image"
      />
    </div>
  </div>

  <component :is="RegisterFirstKey" />
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.auth {
  display: grid;
  grid-template-columns: min(v-bind(getContentWidth), 100%) 1fr;
  width: 1300px;
  max-width: 100%;
  margin: auto;
  padding: cdsSpace('3xl') cdsSpace('lg');
  gap: cdsSpace('3xl');

  @include cdsMediaQueryDown('xl') {
    padding: cdsSpace('xl') cdsSpace('lg');
  }

  @include cdsMediaQueryDown('lg') {
    grid-template-columns: min(v-bind(getContentWidth), 100%);
    justify-content: center;
    padding: 0 cdsSpace('sm');
  }

  &__content {
    padding: cdsSpace('lg') 0;
  }

  &__image-container {
    @include cdsMediaQueryDown('lg') {
      display: none;
    }
  }

  &__image {
    border-radius: cdsBorderRadius('xl');
    top: cdsSpace('3xl');
    left: 0;
    position: sticky;
    height: 80vh;
    object-fit: cover;
  }

  &__logo {
    display: flex;
    margin-bottom: cdsSpace('2xl');

    @include cdsMediaQueryDown('lg') {
      margin-bottom: cdsSpace('3xl');
    }
  }
}
</style>
